import { AppRouteMeta } from '@sennder/shell-utilities'

import { MicrofrontendId } from './microfrontends'
import {
  redirectToOrderDetails,
  checkDispatchingBoardAccess,
  restrictForCharteringOfficeUsers,
  redirectTrackAndTraceView,
  redirectToShipment,
  checkSennlabAccess,
  checkSearchBoardAccess,
  redirectToSingleViewAP,
} from './route-guards'

/**
 * Unique application route
 */
export type AppRoute = keyof typeof routes

/**
 * All routes of the application, ordered alphabetically by path.
 * Each route has navigation guards, analytical and logging contexts.
 */
export const routes = {
  '/blocklisting-dashboard': {
    name: 'blocklisting-dashboard',
    mf: 'blocklisting',
    context: {
      analytics: {
        module: 'vetting_micro_frontends',
        submodule: '',
      },
      logger: {
        codeOwners: 'vetting',
        module: 'vetting_micro_frontends',
      },
    },
  },
  '/blocklisting/:blocklistingId': {
    name: 'blocklisting-review',
    mf: 'vetting-review',
    context: {
      analytics: {
        module: 'vetting-review-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'vetting',
        module: 'vetting-review-mf-component',
      },
    },
  },
  '/carriers': {
    name: 'carrier-search',
    mf: 'carrier-search',
    context: {
      analytics: {
        module: 'carrier-search-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'carrier-data',
        module: 'carrier-search-mf-component',
      },
    },
  },
  '/carriers/:carrierId': {
    name: 'carrier-profile',
    mf: 'carrier-profile',
    context: {
      analytics: {
        module: 'carrier-profile-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'carrier-data',
        module: 'carrier-profile-mf-component',
      },
    },
  },
  '/contract-management': {
    name: 'contract-management',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'contract-management',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'contract-management',
      },
    },
  },
  '/dashboard': {
    name: 'dashboard',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'dashboard',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'dashboard',
      },
    },
  },
  '/dashboard/dispatching': {
    name: 'dispatching-board',
    mf: 'dispatching',
    context: {
      analytics: {
        module: 'dispatching-board-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'dispatching-board-mf-component',
      },
    },
    guards: [checkDispatchingBoardAccess],
  },
  '/dashboard/es-execution': {
    name: 'old-es-execution-board',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'es-execution-board',
      },
      logger: {
        codeOwners: 'transit',
        module: 'es-execution-board',
      },
    },
    guards: [redirectTrackAndTraceView],
  },
  // Legacy route, only redirect is supported
  '/dashboard/finance': {
    name: 'finance-dashboard',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'finance-dashboard',
      },
      logger: {
        codeOwners: 'settlement',
        module: 'finance-dashboard',
      },
    },
    guards: [redirectToSingleViewAP],
  },
  '/dashboard/overview': {
    name: 'search-board',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'search-board',
      },
      logger: {
        codeOwners: 'transit',
        module: 'search-board',
      },
    },
    guards: [checkSearchBoardAccess],
  },
  '/facilities': {
    name: 'facility-management',
    mf: 'facility-management',
    context: {
      analytics: {
        module: 'facility-management-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'facility-management',
        module: 'facility-management-mf-component',
      },
    },
  },
  '/analysis': {
    name: 'analysis-board',
    mf: 'analysis',
    context: {
      analytics: {
        module: 'analysis-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'analysis-board',
        module: 'analysis-mf-component',
      },
    },
  },
  // Legacy route, only redirect is supported
  '/finance/ordering/:orderGroupId(\\d+):letter([A-Z]+)': {
    name: 'finance-ordering',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'finance-ordering',
      },
      logger: {
        codeOwners: 'settlement',
        module: 'finance-ordering',
      },
    },
    guards: [redirectToOrderDetails],
  },
  // Legacy route, only redirect is supported
  '/finance/ordering/:orderGroupId(\\d+):letter([A-Z]+)/:financeView': {
    name: 'finance-view',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'finance-view',
      },
      logger: {
        codeOwners: 'settlement',
        module: 'finance-view',
      },
    },
    guards: [redirectToOrderDetails],
  },
  '/login': {
    name: 'login',
    mf: 'auth',
    context: {
      analytics: {
        module: 'internal-auth-mf-component-v2',
        submodule: '',
      },
      logger: {
        codeOwners: 'carrier-core',
        module: 'internal-auth-mf-component-v2',
      },
    },
    fullscreenLayout: true,
    public: true,
  },
  '/new_shipment/:id': {
    name: 'new_shipment',
    mf: 'shipment',
    context: {
      analytics: {
        module: 'shipment-mf-component',
        submodule: 'shipment',
      },
      logger: {
        codeOwners: 'ingestion',
        module: 'shipment',
      },
    },
    guards: [redirectToShipment],
  },
  '/ordering/:orderGroupId(\\d+):letter([A-Z]+)': {
    name: 'ordering',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'ordering',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'ordering',
      },
    },
  },
  '/order': {
    name: 'ordering-legacy-redirect',
    mf: 'octopus',
    context: {
      analytics: {
        module: 'octopus-core-mf-component',
        submodule: 'ordering-legacy-redirect',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'ordering-legacy-redirect',
      },
    },
  },
  '/planner': {
    name: 'planner',
    mf: 'planner',
    context: {
      analytics: {
        module: 'planner',
        submodule: '',
      },
      logger: {
        codeOwners: 'asset-planning',
        module: 'planner-mf-component',
      },
    },
    guards: [restrictForCharteringOfficeUsers],
  },
  '/contracts': {
    name: 'contracts',
    mf: 'contracts',
    context: {
      analytics: {
        module: 'contracts',
        submodule: '',
      },
      logger: {
        codeOwners: 'asset-planning',
        module: 'chartering-contracts',
      },
    },
    guards: [restrictForCharteringOfficeUsers],
  },
  '/marketplace': {
    name: 'marketplace',
    mf: 'marketplace',
    context: {
      analytics: {
        module: 'marketplace',
        submodule: '',
      },
      logger: {
        codeOwners: 'pod-discovery',
        module: 'marketplace-mf-component',
      },
    },
    guards: [restrictForCharteringOfficeUsers],
  },
  '/order-details/:idForStaff': {
    name: 'order-details',
    mf: 'marketplace',
    context: {
      analytics: {
        module: 'order-details',
        submodule: '',
      },
      logger: {
        module: 'marketplace-mf-component',
        codeOwners: 'pod-discovery',
      },
    },
    guards: [restrictForCharteringOfficeUsers],
  },
  '/senn-teams': {
    name: 'senn-teams',
    mf: 'sennteams',
    context: {
      analytics: {
        module: 'sennteams-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'sennteams-mf-component',
      },
    },
  },
  '/sennlab': {
    name: 'sennlab',
    mf: 'sennlab',
    context: {
      analytics: {
        module: 'sennlab-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'sennlab-mf-component',
      },
    },
    guards: [checkSennlabAccess],
  },
  '/shipment/:id': {
    name: 'shipment',
    mf: 'shipment',
    context: {
      analytics: {
        module: 'shipment-mf-component',
        submodule: 'shipment',
      },
      logger: {
        codeOwners: 'ingestion',
        module: 'shipment',
      },
    },
  },
  '/single-view': {
    name: 'single-view',
    mf: 'single-view',
    context: {
      analytics: {
        module: 'single-view-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'single-view-mf-component',
      },
    },
  },
  '/spotter': {
    name: 'spotter',
    mf: 'spotter',
    context: {
      analytics: {
        module: 'spotter-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'shipper-integrations',
        module: 'spotter-mf-component',
      },
    },
  },
  '/vetting-dashboard': {
    name: 'vetting-dashboard',
    mf: 'vetting-dashboard',
    context: {
      analytics: {
        module: 'vetting_micro_frontends',
        submodule: '',
      },
      logger: {
        codeOwners: 'vetting',
        module: 'vetting_micro_frontends',
      },
    },
  },
  '/vetting/:vettingId': {
    name: 'vetting-review',
    mf: 'vetting-review',
    context: {
      analytics: {
        module: 'vetting-review-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'vetting',
        module: 'vetting-review-mf-component',
      },
    },
  },
  '/archive/:orderId': {
    name: 'archived-order',
    mf: 'archived-order',
    context: {
      analytics: {
        module: 'archived-order-mf-component',
        submodule: '',
      },
      logger: {
        codeOwners: 'ops-compass',
        module: 'archived-order-mf-component',
      },
    },
  },
} as const satisfies {
  [TRoute: string]: AppRouteMeta<MicrofrontendId>
}
