import { AppNavigationGuard } from '@sennder/shell-utilities'

import { INITIAL_ROUTE_PATH } from '@/router'
import { getStateData, getStateFeatureFlags } from '@/store'
import { canAccessCharteringPages, canAccessSennlab } from './user-access'
import { isProductionEnv } from '@/common/config'

/**
 * Router Navigation Guards
 *
 * NAMING CONVENTIONS
 *
 * Route guard name should be a verb that describes what the guard does.
 *
 * - check{Page}Access: Check if the user has access to the page
 * - redirect{Page}: Redirect the user to a different page based on some criteria
 * - restrict{Criteria}: Restrict access to the page based on some criteria
 */

/**
 * Automatically redirect AOMs to Single View if REDIRECT_DISPATCHING-BOARD is enabled
 */
export const checkDispatchingBoardAccess: AppNavigationGuard = async () => {
  const data = getStateData()

  const shouldRedirectToSingleView =
    data.featureFlags['REDIRECT_DISPATCHING-BOARD']

  if (!shouldRedirectToSingleView) {
    return true
  } else {
    return '/single-view/carrier_find/carrier_find_all'
  }
}

/**
 * Automatically redirect users to Analysis board if REDIRECT_SEARCH-BOARD is enabled
 */
export const checkSearchBoardAccess: AppNavigationGuard = async () => {
  const data = getStateData()

  const shouldRedirectToSearchBoard = data.featureFlags['REDIRECT_SEARCH-BOARD']

  if (!shouldRedirectToSearchBoard) {
    return true
  } else {
    return '/analysis'
  }
}

/**
 * Redirect users to the Order Details page.
 */
export const redirectToOrderDetails: AppNavigationGuard = async (to) => {
  return `/ordering/${to.params.orderGroupId}${to.params.letter}`
}

export const redirectTrackAndTraceView: AppNavigationGuard = async () => {
  if (getStateFeatureFlags()['transit-redirect-execution-board']) {
    return `/single-view/transit/transit_all`
  }
  return true
}

/**
 * Enable this page only in non-prod envs
 */
export const restrictInProduction: AppNavigationGuard = async () => {
  if (isProductionEnv()) {
    return { path: INITIAL_ROUTE_PATH }
  }
  return true
}

/**
 * This function determines whether the module should be visible to the user
 * based on the organization type.
 * Consider permission screen for the users that are not part of the chartering office
 */
export const restrictForCharteringOfficeUsers: AppNavigationGuard =
  async () => {
    if (canAccessCharteringPages()) {
      return true
    }
    return { path: INITIAL_ROUTE_PATH }
  }

export const redirectToShipment: AppNavigationGuard = (to) => {
  return { name: 'shipment', params: to.params }
}

export const checkSennlabAccess: AppNavigationGuard = () => {
  if (canAccessSennlab()) {
    return true
  }

  return { path: INITIAL_ROUTE_PATH }
}

export const redirectToSingleViewAP: AppNavigationGuard = () => {
  return '/single-view/ap-view/ap-view_all'
}
